import * as React from "react"

import Fade from "react-reveal/Fade"

import arrow from "../images/arrow.svg"

import Layout from "../components/layout"
import Seo from "../components/seo"

import SectionNoBG from "../components/section_no_bg"
import SectionImageBG from "../components/section_image_bg"

import navbarTexts from "../../content/accounting/nav.json"
 import introTexts from "../../content/accounting/intro.json"
 import section2Texts from "../../content/accounting/section_2.json"
 import section3Texts from "../../content/accounting/section_3.json"
 import section4Texts from "../../content/accounting/section_4.json"
 import section5Texts from "../../content/accounting/section_5.json"


import "../styles/accounting.scss"


const Accounting = () => {

  return (
    <Layout pageTitle="Account &amp; Advisory" customHeaderFooter={true}>
      <nav id="accounting-nav">
        <div className="container-xxl px-lg-4">
          <div className="d-flex flex-row align-items-center nav-logo-container "
          >
            <svg viewBox="0 0 110 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8614 25.1183L11.6669 26.6324H16.1392L19.2893 1.08704H14.7003L13.6503 9.62805C12.6003 8.38572 10.8113 7.76456 8.86685 7.76456C3.73341 7.76456 0 12.1127 0 18.1302C0 23.1384 2.56672 27.0983 7.1946 27.0983C9.10019 27.0983 10.8891 26.4383 11.8614 25.1183ZM8.20573 22.6725C5.83346 22.6725 4.70566 20.7314 4.70566 18.0138C4.70566 14.4421 6.61125 12.1904 9.25575 12.1904C11.7058 12.1904 12.8336 14.1315 12.8336 16.7326C12.8336 20.2267 10.8502 22.6725 8.20573 22.6725Z" fill="#F4F6F5" />
              <path d="M26.9512 5.35754C25.3567 5.35754 24.4622 4.27051 24.4622 2.87288C24.4622 1.20351 25.7456 0 27.4179 0C29.0123 0 29.9068 1.08704 29.9068 2.48466C29.9068 4.15404 28.6234 5.35754 26.9512 5.35754ZM19.601 34.0088C23.49 34.0088 25.94 31.5241 26.4456 27.2924L28.779 8.23043H24.19L21.8955 26.8654C21.6622 28.7289 20.8066 29.5441 19.1343 29.5441C18.5121 29.5441 17.9288 29.4277 17.3454 29.2336L16.2565 33.2711C17.3454 33.7758 18.4343 34.0088 19.601 34.0088Z" fill="#F4F6F5" />
              <path d="M39.2537 22.6725C36.8814 22.6725 35.7536 20.7314 35.7536 18.0138C35.7536 14.4421 37.6592 12.1904 40.3037 12.1904C42.7538 12.1904 43.8816 14.1315 43.8816 16.7326C43.8816 20.2267 41.8982 22.6725 39.2537 22.6725ZM48.5095 26.9818L49.4428 23.2548C48.2761 23.1384 47.8872 22.5172 47.8872 21.469C47.8872 21.0031 47.965 20.3431 48.0428 19.7608L49.4428 8.23043H44.8927L44.7371 9.66687C43.6871 8.38572 41.8593 7.76456 39.9148 7.76456C34.7814 7.76456 31.048 12.1127 31.048 18.1691C31.048 23.1384 33.5758 27.0983 38.2815 27.0983C40.5371 27.0983 42.676 26.2054 43.6871 24.4972C44.5427 26.3995 46.3705 27.1759 48.5095 26.9818ZM45.3983 5.35754C47.0705 5.35754 48.315 4.15404 48.315 2.48466C48.315 1.08704 47.4594 0 45.865 0C44.1927 0 42.9482 1.20351 42.9482 2.87288C42.9482 4.27051 43.8038 5.35754 45.3983 5.35754ZM37.8148 5.35754C39.487 5.35754 40.7315 4.15404 40.7315 2.48466C40.7315 1.08704 39.8759 0 38.2815 0C36.6092 0 35.3647 1.20351 35.3647 2.87288C35.3647 4.27051 36.2203 5.35754 37.8148 5.35754Z" fill="#F4F6F5" />
              <path d="M57.818 21.4302L60.5792 18.829L64.6237 26.6324H69.8349L63.9237 15.6456L71.7016 8.23043H65.4015L58.6736 14.7138L60.3458 1.08704H55.7568L52.6068 26.6324H57.1957L57.818 21.4302Z" fill="#F4F6F5" />
              <path d="M79.2651 8.23043H74.7538L72.4982 26.6324H77.0872L78.2928 16.7326C78.6428 13.7821 80.4706 12.1904 82.5707 12.1904C84.2818 12.1904 85.0985 13.1997 85.0985 15.2962C85.0985 15.8397 85.0207 16.3444 84.9818 16.7714L83.7763 26.6324H88.3652L89.6486 16.2279C89.7264 15.5679 89.7653 14.9079 89.7653 14.3256C89.7653 10.4433 87.8208 7.76456 83.8151 7.76456C81.8707 7.76456 80.0817 8.38572 79.0706 9.74452L79.2651 8.23043Z" fill="#F4F6F5" />
              <path d="M98.09 15.4903C98.5956 13.1997 100.151 11.8409 102.057 11.8409C104.118 11.8409 105.09 13.4327 105.09 15.4903H98.09ZM97.6622 19.2173H109.29C109.562 18.2467 109.718 17.1985 109.718 16.0726C109.718 11.8798 107.579 7.76456 102.368 7.76456C96.9233 7.76456 92.9955 12.3456 92.9955 18.2079C92.9955 22.789 95.29 27.0983 101.24 27.0983C103.496 27.0983 105.712 26.4771 107.735 25.2736L105.712 21.3914C104.235 22.3619 102.718 22.789 101.318 22.789C98.6345 22.789 97.7011 21.1972 97.6622 19.2173Z" fill="#F4F6F5" />
              <path d="M5.06921 43.8397L5.80806 45.8503H6.76495L3.83374 37.8803H3.10699L0.175781 45.8503H1.13266L1.87152 43.8397H5.06921ZM4.75428 42.9676H2.18645L3.47036 39.4792L4.75428 42.9676Z" fill="#F4F6F5" />
              <path d="M8.29235 43.0281C8.29235 41.8532 8.92219 40.9084 10.0002 40.9084C10.5816 40.9084 10.9571 41.1749 11.3205 41.5625L11.9019 40.9206C11.3568 40.3392 10.7391 40.0606 10.0002 40.0606C8.35291 40.0606 7.42025 41.4172 7.42025 43.0281C7.42025 44.6391 8.35291 45.9957 10.0002 45.9957C10.7391 45.9957 11.3568 45.7171 11.9019 45.1357L11.3205 44.4937C10.9571 44.8813 10.5816 45.1478 10.0002 45.1478C8.92219 45.1478 8.29235 44.203 8.29235 43.0281Z" fill="#F4F6F5" />
              <path d="M13.5915 43.0281C13.5915 41.8532 14.2214 40.9084 15.2994 40.9084C15.8808 40.9084 16.2563 41.1749 16.6196 41.5625L17.201 40.9206C16.656 40.3392 16.0382 40.0606 15.2994 40.0606C13.6521 40.0606 12.7194 41.4172 12.7194 43.0281C12.7194 44.6391 13.6521 45.9957 15.2994 45.9957C16.0382 45.9957 16.656 45.7171 17.201 45.1357L16.6196 44.4937C16.2563 44.8813 15.8808 45.1478 15.2994 45.1478C14.2214 45.1478 13.5915 44.203 13.5915 43.0281Z" fill="#F4F6F5" />
              <path d="M20.5865 45.1478C19.5206 45.1478 18.8907 44.203 18.8907 43.0281C18.8907 41.8532 19.5206 40.9084 20.5865 40.9084C21.6403 40.9084 22.2822 41.8532 22.2822 43.0281C22.2822 44.203 21.6403 45.1478 20.5865 45.1478ZM20.5865 45.9957C22.2095 45.9957 23.1543 44.6391 23.1543 43.0281C23.1543 41.4172 22.2095 40.0606 20.5865 40.0606C18.9634 40.0606 18.0186 41.4172 18.0186 43.0281C18.0186 44.6391 18.9634 45.9957 20.5865 45.9957Z" fill="#F4F6F5" />
              <path d="M28.1187 45.172V45.8503H28.9908V40.2059H28.1187V43.4399C28.1187 44.4089 27.4767 45.1478 26.6773 45.1478C25.9021 45.1478 25.3207 44.6875 25.3207 43.3915V40.2059H24.4486V43.4278C24.4486 45.0267 25.1875 45.9957 26.6168 45.9957C27.2708 45.9957 27.7796 45.6929 28.1187 45.172Z" fill="#F4F6F5" />
              <path d="M31.5795 40.8842V40.2059H30.7074V45.8503H31.5795V42.6163C31.5795 41.6473 32.2214 40.9084 33.0208 40.9084C33.796 40.9084 34.3774 41.3687 34.3774 42.6647V45.8503H35.2495V42.6284C35.2495 41.0296 34.5107 40.0606 33.0814 40.0606C32.4273 40.0606 31.9186 40.3634 31.5795 40.8842Z" fill="#F4F6F5" />
              <path d="M38.1652 41.0538H39.7035V40.2059H38.1652V38.5465H37.2931V40.2059H36.3241V41.0538H37.2931V44.1061C37.2931 45.2447 37.7534 45.9957 38.9404 45.9957C39.3401 45.9957 39.6429 45.9109 39.9336 45.7171L39.5581 44.9419C39.3522 45.0751 39.1463 45.1478 38.9041 45.1478C38.468 45.1478 38.1652 44.9055 38.1652 44.0577V41.0538Z" fill="#F4F6F5" />
              <path d="M47.3622 42.1439V43.3188C47.3622 44.4695 46.805 45.1236 45.7149 45.1236C44.6005 45.1236 43.9828 44.4574 43.9828 43.6337C43.9828 42.8101 44.6126 42.1439 45.6906 42.1439H47.3622ZM48.2585 42.1439H49.4213V41.2718H45.6906C44.758 41.2718 44.2008 40.6783 44.2008 39.9394C44.2008 39.2006 44.7459 38.6071 45.7027 38.6071C46.3205 38.6071 46.7808 38.8493 47.241 39.5034L47.9314 38.9341C47.3864 38.1226 46.6354 37.735 45.6906 37.735C44.1766 37.735 43.2924 38.7403 43.2924 39.9394C43.2924 40.6299 43.6194 41.2839 44.2977 41.6836C43.4741 42.0349 43.0743 42.8222 43.0743 43.6459C43.0743 44.9055 44.0433 45.9957 45.6906 45.9957C47.35 45.9957 48.2585 44.9177 48.2585 43.3188V42.1439Z" fill="#F4F6F5" />
              <path d="M57.3869 43.8397L58.1258 45.8503H59.0826L56.1514 37.8803H55.4247L52.4935 45.8503H53.4504L54.1892 43.8397H57.3869ZM57.072 42.9676H54.5041L55.7881 39.4792L57.072 42.9676Z" fill="#F4F6F5" />
              <path d="M62.1847 45.1478C61.2035 45.1478 60.61 44.2515 60.61 43.0281C60.61 41.8048 61.2035 40.9084 62.1847 40.9084C63.1658 40.9084 63.7593 41.8532 63.7593 43.0281C63.7593 44.203 63.1658 45.1478 62.1847 45.1478ZM63.735 45.172V45.8503H64.6071V37.8803H63.735V40.8842C63.3959 40.3634 62.7903 40.0606 62.1241 40.0606C60.61 40.0606 59.7379 41.4172 59.7379 43.0281C59.7379 44.6391 60.61 45.9957 62.1241 45.9957C62.7903 45.9957 63.3959 45.6929 63.735 45.172Z" fill="#F4F6F5" />
              <path d="M68.5155 45.8503L70.6352 40.2059H69.7025L68.14 44.4089L66.5775 40.2059H65.6448L67.7645 45.8503H68.5155Z" fill="#F4F6F5" />
              <path d="M72.1175 38.9826C72.4203 38.9826 72.6747 38.7282 72.6747 38.4254C72.6747 38.1226 72.4203 37.8682 72.1175 37.8682C71.8147 37.8682 71.5603 38.1226 71.5603 38.4254C71.5603 38.7282 71.8147 38.9826 72.1175 38.9826ZM72.5536 45.8503V40.2059H71.6815V45.8503H72.5536Z" fill="#F4F6F5" />
              <path d="M75.8465 45.9957C77.082 45.9957 77.8935 45.3537 77.8935 44.2999C77.8935 43.0281 76.7307 42.7495 76.004 42.58C75.2167 42.3983 74.8775 42.2408 74.8775 41.6836C74.8775 41.1507 75.1925 40.8842 75.8587 40.8842C76.3795 40.8842 76.7671 41.0417 77.1547 41.3324L77.6392 40.642C77.1668 40.2665 76.5491 40.0606 75.8829 40.0606C74.708 40.0606 74.0054 40.7146 74.0054 41.72C74.0054 42.9554 75.0229 43.234 75.7496 43.4036C76.4885 43.5732 77.0214 43.6822 77.0214 44.2878C77.0214 44.8208 76.6338 45.172 75.8708 45.172C75.2772 45.172 74.7564 44.9661 74.3204 44.5785L73.848 45.3053C74.393 45.7534 75.0835 45.9957 75.8465 45.9957Z" fill="#F4F6F5" />
              <path d="M81.3971 45.1478C80.3312 45.1478 79.7013 44.203 79.7013 43.0281C79.7013 41.8532 80.3312 40.9084 81.3971 40.9084C82.4508 40.9084 83.0928 41.8532 83.0928 43.0281C83.0928 44.203 82.4508 45.1478 81.3971 45.1478ZM81.3971 45.9957C83.0201 45.9957 83.9649 44.6391 83.9649 43.0281C83.9649 41.4172 83.0201 40.0606 81.3971 40.0606C79.774 40.0606 78.8292 41.4172 78.8292 43.0281C78.8292 44.6391 79.774 45.9957 81.3971 45.9957Z" fill="#F4F6F5" />
              <path d="M86.1919 40.9932V40.2059H85.3198V45.8503H86.1919V43.0523C86.1919 41.9622 86.6037 40.9569 87.9603 41.0296V40.0848H87.8271C87.1245 40.0848 86.5553 40.3997 86.1919 40.9932Z" fill="#F4F6F5" />
              <path d="M90.9454 44.4089L89.3829 40.2059H88.4503L90.4731 45.596L90.4004 45.7898C90.146 46.4559 89.7584 47.0252 88.7773 47.3644L89.1286 48.1638C90.1581 47.8125 90.8849 46.9889 91.2119 46.1289L93.4406 40.2059H92.5079L90.9454 44.4089Z" fill="#F4F6F5" />
              <path d="M101.093 43.8397L101.832 45.8503H102.789L99.8579 37.8803H99.1312L96.2 45.8503H97.1569L97.8957 43.8397H101.093ZM100.778 42.9676H98.2106L99.4946 39.4792L100.778 42.9676Z" fill="#F4F6F5" />
              <path d="M104.883 41.2718V38.7524H106.518C107.572 38.7524 108.129 39.2369 108.129 40C108.129 40.7146 107.633 41.2718 106.421 41.2718H104.883ZM107.96 41.6473C108.614 41.4172 109.05 40.86 109.05 40C109.05 38.7403 108.165 37.8803 106.518 37.8803H103.987V45.8503H106.639C108.577 45.8503 109.389 44.8934 109.389 43.6095C109.389 42.4952 108.795 41.9259 107.96 41.6473ZM104.883 44.9782V42.1439H106.603C107.754 42.1439 108.468 42.6163 108.468 43.6095C108.468 44.5422 107.838 44.9782 106.639 44.9782H104.883Z" fill="#F4F6F5" />
            </svg>
            <a class="arrow-button d-none d-md-flex toggleColor pe-3 me-2 contact-us" href= {`mailto:${navbarTexts.cta_email}?subject=${navbarTexts.cta_email_subject}`}><p class="mb-0 hover-show">{navbarTexts.cta_text}</p></a>
          </div>
        </div>
      </nav>
      <Seo title="Account &amp; Advisory" description="Djäkne Account & Advisory AB is a consulting firm specialized in supporting software startups on their scaleup journey." />
      <SectionNoBG
        classes="first-section"
        headline={introTexts.headline}
        text={introTexts.text}
        narrow={true}
      >
      </SectionNoBG>
      <Fade bottom duration={1500} fraction={0.1} distance={"200px"}>
        <SectionNoBG
                  headline={section2Texts.headline}
                  text={section2Texts.text}withImage="right"
                  image={section2Texts.image}
        >
        </SectionNoBG>
        <SectionNoBG
          headline={section3Texts.headline}
          text={section3Texts.text}
          withImage="left"
          image={section3Texts.image}
        ></SectionNoBG>
        <SectionNoBG
          headline={section4Texts.headline}
          text={section4Texts.text}
          narrow={true}
        >
          <a
            href= {`mailto:${section4Texts.cta_email}?subject=${section4Texts.cta_email_subject}`}
            className=" arrow-button mt-4 me-5 d-block d-md-inline-block toggleColor"
          >
            <p className="mb-0 hover-show">{section4Texts.cta_text}</p>
            <img alt="arrow" className="mx-3" src={arrow} width="36" />
          </a>
        </SectionNoBG>
        <SectionImageBG
                    headline={section5Texts.headline}
                    text={section5Texts.text}
                    withImage="left"
                    background={section5Texts.image}>
          <a
            href= {`mailto:${section5Texts.cta_email}?subject=${section5Texts.cta_email_subject}`}
            className=" arrow-button mt-4 me-5 d-block d-md-inline-block "
          >
            <p className="mb-0 hover-show">{section5Texts.cta_text}</p>
            <img alt="arrow" className="mx-3" src={arrow} width="36" />
          </a>
        </SectionImageBG>
        <footer id="accounting-footer">
        <div className="container-xxl px-lg-4">
          <div className="d-flex flex-row align-items-center nav-logo-container "
          >
            <svg viewBox="0 0 110 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8614 25.1183L11.6669 26.6324H16.1392L19.2893 1.08704H14.7003L13.6503 9.62805C12.6003 8.38572 10.8113 7.76456 8.86685 7.76456C3.73341 7.76456 0 12.1127 0 18.1302C0 23.1384 2.56672 27.0983 7.1946 27.0983C9.10019 27.0983 10.8891 26.4383 11.8614 25.1183ZM8.20573 22.6725C5.83346 22.6725 4.70566 20.7314 4.70566 18.0138C4.70566 14.4421 6.61125 12.1904 9.25575 12.1904C11.7058 12.1904 12.8336 14.1315 12.8336 16.7326C12.8336 20.2267 10.8502 22.6725 8.20573 22.6725Z" fill="#F4F6F5" />
              <path d="M26.9512 5.35754C25.3567 5.35754 24.4622 4.27051 24.4622 2.87288C24.4622 1.20351 25.7456 0 27.4179 0C29.0123 0 29.9068 1.08704 29.9068 2.48466C29.9068 4.15404 28.6234 5.35754 26.9512 5.35754ZM19.601 34.0088C23.49 34.0088 25.94 31.5241 26.4456 27.2924L28.779 8.23043H24.19L21.8955 26.8654C21.6622 28.7289 20.8066 29.5441 19.1343 29.5441C18.5121 29.5441 17.9288 29.4277 17.3454 29.2336L16.2565 33.2711C17.3454 33.7758 18.4343 34.0088 19.601 34.0088Z" fill="#F4F6F5" />
              <path d="M39.2537 22.6725C36.8814 22.6725 35.7536 20.7314 35.7536 18.0138C35.7536 14.4421 37.6592 12.1904 40.3037 12.1904C42.7538 12.1904 43.8816 14.1315 43.8816 16.7326C43.8816 20.2267 41.8982 22.6725 39.2537 22.6725ZM48.5095 26.9818L49.4428 23.2548C48.2761 23.1384 47.8872 22.5172 47.8872 21.469C47.8872 21.0031 47.965 20.3431 48.0428 19.7608L49.4428 8.23043H44.8927L44.7371 9.66687C43.6871 8.38572 41.8593 7.76456 39.9148 7.76456C34.7814 7.76456 31.048 12.1127 31.048 18.1691C31.048 23.1384 33.5758 27.0983 38.2815 27.0983C40.5371 27.0983 42.676 26.2054 43.6871 24.4972C44.5427 26.3995 46.3705 27.1759 48.5095 26.9818ZM45.3983 5.35754C47.0705 5.35754 48.315 4.15404 48.315 2.48466C48.315 1.08704 47.4594 0 45.865 0C44.1927 0 42.9482 1.20351 42.9482 2.87288C42.9482 4.27051 43.8038 5.35754 45.3983 5.35754ZM37.8148 5.35754C39.487 5.35754 40.7315 4.15404 40.7315 2.48466C40.7315 1.08704 39.8759 0 38.2815 0C36.6092 0 35.3647 1.20351 35.3647 2.87288C35.3647 4.27051 36.2203 5.35754 37.8148 5.35754Z" fill="#F4F6F5" />
              <path d="M57.818 21.4302L60.5792 18.829L64.6237 26.6324H69.8349L63.9237 15.6456L71.7016 8.23043H65.4015L58.6736 14.7138L60.3458 1.08704H55.7568L52.6068 26.6324H57.1957L57.818 21.4302Z" fill="#F4F6F5" />
              <path d="M79.2651 8.23043H74.7538L72.4982 26.6324H77.0872L78.2928 16.7326C78.6428 13.7821 80.4706 12.1904 82.5707 12.1904C84.2818 12.1904 85.0985 13.1997 85.0985 15.2962C85.0985 15.8397 85.0207 16.3444 84.9818 16.7714L83.7763 26.6324H88.3652L89.6486 16.2279C89.7264 15.5679 89.7653 14.9079 89.7653 14.3256C89.7653 10.4433 87.8208 7.76456 83.8151 7.76456C81.8707 7.76456 80.0817 8.38572 79.0706 9.74452L79.2651 8.23043Z" fill="#F4F6F5" />
              <path d="M98.09 15.4903C98.5956 13.1997 100.151 11.8409 102.057 11.8409C104.118 11.8409 105.09 13.4327 105.09 15.4903H98.09ZM97.6622 19.2173H109.29C109.562 18.2467 109.718 17.1985 109.718 16.0726C109.718 11.8798 107.579 7.76456 102.368 7.76456C96.9233 7.76456 92.9955 12.3456 92.9955 18.2079C92.9955 22.789 95.29 27.0983 101.24 27.0983C103.496 27.0983 105.712 26.4771 107.735 25.2736L105.712 21.3914C104.235 22.3619 102.718 22.789 101.318 22.789C98.6345 22.789 97.7011 21.1972 97.6622 19.2173Z" fill="#F4F6F5" />
              <path d="M5.06921 43.8397L5.80806 45.8503H6.76495L3.83374 37.8803H3.10699L0.175781 45.8503H1.13266L1.87152 43.8397H5.06921ZM4.75428 42.9676H2.18645L3.47036 39.4792L4.75428 42.9676Z" fill="#F4F6F5" />
              <path d="M8.29235 43.0281C8.29235 41.8532 8.92219 40.9084 10.0002 40.9084C10.5816 40.9084 10.9571 41.1749 11.3205 41.5625L11.9019 40.9206C11.3568 40.3392 10.7391 40.0606 10.0002 40.0606C8.35291 40.0606 7.42025 41.4172 7.42025 43.0281C7.42025 44.6391 8.35291 45.9957 10.0002 45.9957C10.7391 45.9957 11.3568 45.7171 11.9019 45.1357L11.3205 44.4937C10.9571 44.8813 10.5816 45.1478 10.0002 45.1478C8.92219 45.1478 8.29235 44.203 8.29235 43.0281Z" fill="#F4F6F5" />
              <path d="M13.5915 43.0281C13.5915 41.8532 14.2214 40.9084 15.2994 40.9084C15.8808 40.9084 16.2563 41.1749 16.6196 41.5625L17.201 40.9206C16.656 40.3392 16.0382 40.0606 15.2994 40.0606C13.6521 40.0606 12.7194 41.4172 12.7194 43.0281C12.7194 44.6391 13.6521 45.9957 15.2994 45.9957C16.0382 45.9957 16.656 45.7171 17.201 45.1357L16.6196 44.4937C16.2563 44.8813 15.8808 45.1478 15.2994 45.1478C14.2214 45.1478 13.5915 44.203 13.5915 43.0281Z" fill="#F4F6F5" />
              <path d="M20.5865 45.1478C19.5206 45.1478 18.8907 44.203 18.8907 43.0281C18.8907 41.8532 19.5206 40.9084 20.5865 40.9084C21.6403 40.9084 22.2822 41.8532 22.2822 43.0281C22.2822 44.203 21.6403 45.1478 20.5865 45.1478ZM20.5865 45.9957C22.2095 45.9957 23.1543 44.6391 23.1543 43.0281C23.1543 41.4172 22.2095 40.0606 20.5865 40.0606C18.9634 40.0606 18.0186 41.4172 18.0186 43.0281C18.0186 44.6391 18.9634 45.9957 20.5865 45.9957Z" fill="#F4F6F5" />
              <path d="M28.1187 45.172V45.8503H28.9908V40.2059H28.1187V43.4399C28.1187 44.4089 27.4767 45.1478 26.6773 45.1478C25.9021 45.1478 25.3207 44.6875 25.3207 43.3915V40.2059H24.4486V43.4278C24.4486 45.0267 25.1875 45.9957 26.6168 45.9957C27.2708 45.9957 27.7796 45.6929 28.1187 45.172Z" fill="#F4F6F5" />
              <path d="M31.5795 40.8842V40.2059H30.7074V45.8503H31.5795V42.6163C31.5795 41.6473 32.2214 40.9084 33.0208 40.9084C33.796 40.9084 34.3774 41.3687 34.3774 42.6647V45.8503H35.2495V42.6284C35.2495 41.0296 34.5107 40.0606 33.0814 40.0606C32.4273 40.0606 31.9186 40.3634 31.5795 40.8842Z" fill="#F4F6F5" />
              <path d="M38.1652 41.0538H39.7035V40.2059H38.1652V38.5465H37.2931V40.2059H36.3241V41.0538H37.2931V44.1061C37.2931 45.2447 37.7534 45.9957 38.9404 45.9957C39.3401 45.9957 39.6429 45.9109 39.9336 45.7171L39.5581 44.9419C39.3522 45.0751 39.1463 45.1478 38.9041 45.1478C38.468 45.1478 38.1652 44.9055 38.1652 44.0577V41.0538Z" fill="#F4F6F5" />
              <path d="M47.3622 42.1439V43.3188C47.3622 44.4695 46.805 45.1236 45.7149 45.1236C44.6005 45.1236 43.9828 44.4574 43.9828 43.6337C43.9828 42.8101 44.6126 42.1439 45.6906 42.1439H47.3622ZM48.2585 42.1439H49.4213V41.2718H45.6906C44.758 41.2718 44.2008 40.6783 44.2008 39.9394C44.2008 39.2006 44.7459 38.6071 45.7027 38.6071C46.3205 38.6071 46.7808 38.8493 47.241 39.5034L47.9314 38.9341C47.3864 38.1226 46.6354 37.735 45.6906 37.735C44.1766 37.735 43.2924 38.7403 43.2924 39.9394C43.2924 40.6299 43.6194 41.2839 44.2977 41.6836C43.4741 42.0349 43.0743 42.8222 43.0743 43.6459C43.0743 44.9055 44.0433 45.9957 45.6906 45.9957C47.35 45.9957 48.2585 44.9177 48.2585 43.3188V42.1439Z" fill="#F4F6F5" />
              <path d="M57.3869 43.8397L58.1258 45.8503H59.0826L56.1514 37.8803H55.4247L52.4935 45.8503H53.4504L54.1892 43.8397H57.3869ZM57.072 42.9676H54.5041L55.7881 39.4792L57.072 42.9676Z" fill="#F4F6F5" />
              <path d="M62.1847 45.1478C61.2035 45.1478 60.61 44.2515 60.61 43.0281C60.61 41.8048 61.2035 40.9084 62.1847 40.9084C63.1658 40.9084 63.7593 41.8532 63.7593 43.0281C63.7593 44.203 63.1658 45.1478 62.1847 45.1478ZM63.735 45.172V45.8503H64.6071V37.8803H63.735V40.8842C63.3959 40.3634 62.7903 40.0606 62.1241 40.0606C60.61 40.0606 59.7379 41.4172 59.7379 43.0281C59.7379 44.6391 60.61 45.9957 62.1241 45.9957C62.7903 45.9957 63.3959 45.6929 63.735 45.172Z" fill="#F4F6F5" />
              <path d="M68.5155 45.8503L70.6352 40.2059H69.7025L68.14 44.4089L66.5775 40.2059H65.6448L67.7645 45.8503H68.5155Z" fill="#F4F6F5" />
              <path d="M72.1175 38.9826C72.4203 38.9826 72.6747 38.7282 72.6747 38.4254C72.6747 38.1226 72.4203 37.8682 72.1175 37.8682C71.8147 37.8682 71.5603 38.1226 71.5603 38.4254C71.5603 38.7282 71.8147 38.9826 72.1175 38.9826ZM72.5536 45.8503V40.2059H71.6815V45.8503H72.5536Z" fill="#F4F6F5" />
              <path d="M75.8465 45.9957C77.082 45.9957 77.8935 45.3537 77.8935 44.2999C77.8935 43.0281 76.7307 42.7495 76.004 42.58C75.2167 42.3983 74.8775 42.2408 74.8775 41.6836C74.8775 41.1507 75.1925 40.8842 75.8587 40.8842C76.3795 40.8842 76.7671 41.0417 77.1547 41.3324L77.6392 40.642C77.1668 40.2665 76.5491 40.0606 75.8829 40.0606C74.708 40.0606 74.0054 40.7146 74.0054 41.72C74.0054 42.9554 75.0229 43.234 75.7496 43.4036C76.4885 43.5732 77.0214 43.6822 77.0214 44.2878C77.0214 44.8208 76.6338 45.172 75.8708 45.172C75.2772 45.172 74.7564 44.9661 74.3204 44.5785L73.848 45.3053C74.393 45.7534 75.0835 45.9957 75.8465 45.9957Z" fill="#F4F6F5" />
              <path d="M81.3971 45.1478C80.3312 45.1478 79.7013 44.203 79.7013 43.0281C79.7013 41.8532 80.3312 40.9084 81.3971 40.9084C82.4508 40.9084 83.0928 41.8532 83.0928 43.0281C83.0928 44.203 82.4508 45.1478 81.3971 45.1478ZM81.3971 45.9957C83.0201 45.9957 83.9649 44.6391 83.9649 43.0281C83.9649 41.4172 83.0201 40.0606 81.3971 40.0606C79.774 40.0606 78.8292 41.4172 78.8292 43.0281C78.8292 44.6391 79.774 45.9957 81.3971 45.9957Z" fill="#F4F6F5" />
              <path d="M86.1919 40.9932V40.2059H85.3198V45.8503H86.1919V43.0523C86.1919 41.9622 86.6037 40.9569 87.9603 41.0296V40.0848H87.8271C87.1245 40.0848 86.5553 40.3997 86.1919 40.9932Z" fill="#F4F6F5" />
              <path d="M90.9454 44.4089L89.3829 40.2059H88.4503L90.4731 45.596L90.4004 45.7898C90.146 46.4559 89.7584 47.0252 88.7773 47.3644L89.1286 48.1638C90.1581 47.8125 90.8849 46.9889 91.2119 46.1289L93.4406 40.2059H92.5079L90.9454 44.4089Z" fill="#F4F6F5" />
              <path d="M101.093 43.8397L101.832 45.8503H102.789L99.8579 37.8803H99.1312L96.2 45.8503H97.1569L97.8957 43.8397H101.093ZM100.778 42.9676H98.2106L99.4946 39.4792L100.778 42.9676Z" fill="#F4F6F5" />
              <path d="M104.883 41.2718V38.7524H106.518C107.572 38.7524 108.129 39.2369 108.129 40C108.129 40.7146 107.633 41.2718 106.421 41.2718H104.883ZM107.96 41.6473C108.614 41.4172 109.05 40.86 109.05 40C109.05 38.7403 108.165 37.8803 106.518 37.8803H103.987V45.8503H106.639C108.577 45.8503 109.389 44.8934 109.389 43.6095C109.389 42.4952 108.795 41.9259 107.96 41.6473ZM104.883 44.9782V42.1439H106.603C107.754 42.1439 108.468 42.6163 108.468 43.6095C108.468 44.5422 107.838 44.9782 106.639 44.9782H104.883Z" fill="#F4F6F5" />
            </svg>
            <div className="footer-texts">
              <p class="mb-0"><small>Djäkne Account & Advisory AB</small></p>
              <p class="mb-0"><small>Djäknegatan 9</small></p>
              <p class="mb-0"><small>211 35 Malmö</small></p>
            </div>
          </div>
        </div>
      </footer>
      </Fade>
    </Layout>
  )
}
export default Accounting
