import * as React from "react"
import getFullsizeImage from "../utils/getFullsizeImage"
import "./section_image_bg.module.scss"
function SectionImageBG(props) {
  return (
    <section
      id={props.id}
      className={
        props.classes ? `image-section ${props.classes}` : `image-section`
      }
    >
      <div className="container-fluid image-section-container text-white">
        <div className="image-slider">
          <img
            src={getFullsizeImage(props.background)}
            alt={props.alt ? `${props.alt}` : ``}
          />
        </div>
        <div className="image-section-content d-flex flex-column">
          <div className="container-xxl flex-grow-1 d-flex flex-column justify-content-center">
            <div className="row justify-content-start">
              <div className="col-12">
                {props.sectionHeadline && (
                  <h5 className="mb-4">{props.sectionHeadline}</h5>
                )}
                {props.headline && <h1 className=" mb-4">{props.headline}</h1>}
                {props.content ? (
                  <p
                    className="lead mt-3 col-12 col-md-7"
                    dangerouslySetInnerHTML={{ __html: props.content }}
                  />
                ) : (
                  <p className="lead mt-3 col-12 col-md-7">{props.text}</p>
                )}
              </div>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionImageBG
